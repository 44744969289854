import React from "react"

/* Components */
import Layout from "../../components/layout"
import ContentTemplate from "../../components/contentTemplate"
import HeaderPara from "../../components/headPara"
import CaptionedImage from "../../components/captionedImage"
import PageNav from "../../components/pageNav"
import Divider from "../../components/divider"

/* Images */
import mainImage from "../../images/anywhr-brand-refresh/hero.png"
import mainImageAlt from "../../images/anywhr-brand-refresh/hero-loading.png"
import beforeAfter from "../../images/anywhr-brand-refresh/before-and-after.png"
import kerning from "../../images/anywhr-brand-refresh/kerning.png"
import letterFormComparison from "../../images/anywhr-brand-refresh/letter-form-comparison.png"
import originalBranding from "../../images/anywhr-brand-refresh/original-branding.png"
import pairingHeaderWithParagraph from "../../images/anywhr-brand-refresh/pairing-header-with-paragraph.png"
import playfairDisplayFontSample from "../../images/anywhr-brand-refresh/playfair-display-font-sample.png"
import research from "../../images/anywhr-brand-refresh/research.png"
import sampleLIPUI from "../../images/anywhr-brand-refresh/sample-lip-ui.png"
import styleguide from "../../images/anywhr-brand-refresh/styleguide.png"
import uiHome from "../../images/anywhr-brand-refresh/ui-home.png"
import uiProfile from "../../images/anywhr-brand-refresh/ui-profile.png"

const AnywhrBrandRefresh = () => (
  <Layout>
    <ContentTemplate
      title={"Anywhr Brand Refresh: New look for a new direction"}
      subtitle={"A branding update with new brand colours and typography"}
      year={"2019"}
      tags={"Branding · UI"}
      mainImage={mainImage}
      mainImageAlt={mainImageAlt}
      responsibilities={
        "Creating a brand guide and updating existing component style guide"
      }
      stakeholders={"Marketing - Zelia Leong"}
      duration={"2 weeks"}
    >
      <HeaderPara>
        <p className="subtitle">
          {
            "The refreshed visual identity should still be familiar enough to people who already know Anywhr, yet different enough to reflect the change of direction in Anywhr’s trip planning services: we are no longer just planning surprise trips. Together with the head of Marketing, we refreshed Anywhr's branding to project a more sophisticated image while still keeping it looking fun."
          }
        </p>
      </HeaderPara>
      <HeaderPara header={`Problem`}>
        <p>
          {
            "We wanted to appeal to audiences in their late twenties to thirties, but when we asked our target audience what they thought about the brand, we realised that it looked too young and seemed to target a slightly younger crowd. Words used to describe the brand included cute and childish, which was not what we intended."
          }
        </p>
        <p>
          {
            "We realised that the tetradic colour scheme with the quirky illustrations made the brand seem soft and playful as if made for children. So the lower-effort change was to use a more straightforward brand palette with fewer colours instead. The original brand colour also looked too bright on certain displays, especially mobile phones, and was often mistaken as a red instead of pink."
          }
        </p>
      </HeaderPara>

      <CaptionedImage
        imageSrc={originalBranding}
        caption={"Sample of the original branding"}
      />
      <Divider />
      <HeaderPara header={`Colours: Energizing, Optimistic, Trustworthy`}>
        <p>
          {
            "In colour psychology, Orange is a colour of motivation, positivity, and general enthusiasm for life. So it's also no wonder many travel companies also use orange as their brand colour. It is also not contrastingly different from Anywhr's original brand colour of pink, and I made sure that the shade of orange for Anywhr's brand is different from other well-known brands."
          }
        </p>
        <p>
          {
            "For a simpler palette, I decided to go with just one complementary colour for the secondary colour. A calmer blue balances with the vibrant orange, and is known for its trust and dependability."
          }
        </p>
      </HeaderPara>
      <CaptionedImage imageSrc={research} />
      <HeaderPara header={`Typography: Sophisticated, Fun & Simple`}>
        <p>
          {
            "Playfair Display caught our eye. We instinctively thought that this font encompassed the feelings we wanted the brand to exude. It was great for Anywhr's website headings as a display serif typeface but too different from the original san-serif type used in the logo. Hence, the font selected for body text will need to be used in the logo as well."
          }
        </p>
        <CaptionedImage
          imageSrc={playfairDisplayFontSample}
          caption={"Playfair display sample"}
        />
      </HeaderPara>
      <HeaderPara
        header={`Selecting the font for both body text and the logo wordmark`}
      >
        <p>{"The font needs to:"}</p>
        <ol>
          <li>
            <p>{"Pair well with Playfair Display."}</p>
          </li>
          <li>
            <p>
              {
                "Be available in multiple weights and italics; for the text to be styled suitably in articles."
              }
            </p>
          </li>
          <li>
            <p>
              {
                "Be a sans-serif; as the header font was already a serif, having any other typeface apart from a sans-serif would be over the top."
              }
            </p>
          </li>
          <li>
            <p>
              {
                "Be commercially free-to-use, such as from Google Fonts; I have no budget to purchase font licences."
              }
            </p>
          </li>
        </ol>
        <CaptionedImage
          imageSrc={pairingHeaderWithParagraph}
          caption={"pairing header with paragraph"}
        />
        <p>
          {
            "After shortlisting a few fonts which met all requirements, I narrowed it down to Poppins as it has the same typographic variant as Quicksand -- both font families have the single-storey lower-case letter a."
          }
        </p>
        <CaptionedImage
          imageSrc={letterFormComparison}
          caption={"comparison of 'a's"}
        />
        <p>
          {
            "Poppins also has nine font weights available, which would be useful for setting the typographic styles for Anywhr’s utility app. The high-contrast and space-consuming Playfair Display font is unsuitable for the utility app because it has a data-heavy interface."
          }
        </p>
        <CaptionedImage imageSrc={sampleLIPUI} caption={"sample LIP UI"} />
      </HeaderPara>
      <HeaderPara header={`Making logo's wordmark more legible at small sizes`}>
        <p>
          {
            "The original logo wordmark was using Quicksand. The weight of the font was too light which made it difficult to see the word 'anywhr' when displayed in the website's navigation menu."
          }
        </p>
        <CaptionedImage
          imageSrc={beforeAfter}
          caption={"before-after logo use in website comparison."}
        />
        <p>
          {
            "So, I took this chance to make slight improvements to the readability of the wordmark with the new font. The original llama motif in the logo was kept the same as it was a widely recognised element that people recognised and associated with Anywhr."
          }
        </p>
      </HeaderPara>
      <CaptionedImage imageSrc={kerning} caption={"Logo + kerning"} />
      <Divider />
      <CaptionedImage
        imageSrc={styleguide}
        caption={"Branding applied to UI Styleguide"}
      />
      <CaptionedImage
        imageSrc={uiHome}
        caption={"Refreshed Home page of the Web App"}
      />
      <CaptionedImage
        imageSrc={uiProfile}
        caption={"Refreshed Profile page of the Web App"}
      />
      <PageNav
        prevTitle={"Anywhr Tailor-Made Trips"}
        prevPath={"/2019/anywhr-tailor-made-trips"}
        nextTitle={"Anywhr Itinerary Planner"}
        nextPath={"/2020/anywhr-lip"}
      />
    </ContentTemplate>
  </Layout>
)

export default AnywhrBrandRefresh
